import {
  List,
  Datagrid,
  TextField,
  TextInput,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
  useRecordContext,
  SelectInput,
  TopToolbar,
  DateField,
  Show,
  SimpleShowLayout,
  FilterButton,
  FilterForm,
  Pagination,
  ExportButton,
  useDataProvider,
  ReferenceManyField,
  WithRecord,
} from "react-admin";

import { Stack, useMediaQuery } from "@mui/material";

import { useEffect, useState } from "react";

import { ApproveButton } from "./bank-transfer/approve";
import { RejectButton } from "./bank-transfer/reject";
import { CreateBankTransfer } from "./bank-transfer/CreateBankTransfer";
import { PriceField } from "../customFields";

const filters = [
  <TextInput label="ID" source="id" />,
  <SelectInput
    source="status"
    choices={[
      { id: "approved", name: "Approved" },
      { id: "pending", name: "Pending" },
      { id: "rejected", name: "Rejected" },
    ]}
  />,
  <TextInput label="Reference Code" source="reference_code" />,
  <ReferenceInput
    source="customer_id"
    label="Customer"
    reference="customers"
    allowEmpty
  >
    <AutocompleteInput optionText="title" />
  </ReferenceInput>,
];

const ShowActions = () => {
  const record = useRecordContext();
  const [limit, setLimit] = useState("");
  const [wallet, setWallet] = useState("");
  const dataProvider = useDataProvider();
  useEffect(async () => {
    if (record) {
      const { data } = await dataProvider.getCustomerData(record?.customer_id);
      setLimit(data.one_time_eft_payment_limit);
    }
  }, [record]);
  useEffect(async () => {
    if (record) {
      const { data } = await dataProvider.getWalletById(record?.wallet_id);
      setWallet(data.data);
    }
  }, [record]);
  if (record) {
    const { approved_at: approved, rejected_at: rejected, id } = record;
    const isShown = !(approved || rejected);
    return (
      <TopToolbar>
        {isShown ? (
          <>
            <ApproveButton
              bankTransferId={id}
              limit={limit}
              wallet={wallet}
              customerId={record.customer_id}
            />
            <RejectButton bankTransferId={id} wallet={wallet} />
          </>
        ) : null}
      </TopToolbar>
    );
  }
  return <TopToolbar />;
};

const ListActions = () => {
  return (
    <Stack
      direction="row"
      width={"100%"}
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
    >
      <div>
        <FilterForm filters={filters} />
      </div>
      <div>
        <TopToolbar>
          <FilterButton filters={filters} />
          <ExportButton />
          <CreateBankTransfer />
        </TopToolbar>
      </div>
    </Stack>
  );
};

export const BankTransferList = () => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <List
      actions={<ListActions />}
      sort={{ field: "created_at", order: "DESC" }}
      pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} />}
      filters={<filters />}
    >
      {isSmall ? (
        <List
          linkType="show"
          primaryText={(record) => record.id}
          secondaryText={
            <Stack direction={"row"} justifyContent={"space-between"}>
              <ReferenceField
                source="customer_id"
                reference="customers"
                link="show"
                label="Customer Name"
              >
                <TextField source="title" />
              </ReferenceField>
              <PriceField source="amount" />
            </Stack>
          }
          tertiaryText={() => (
            <DateField source="created_at" showTime locales="tr-TR" />
          )}
        />
      ) : (
        <Datagrid rowClick="show">
          <TextField source="id" />
          <TextField source="amount" />
          <TextField source="status" />
          <TextField source="currency_code" label="Currency" />
          <TextField source="reference_code" />
          <ReferenceField
            source="customer_id"
            reference="customers"
            link="show"
            label="Customer Name"
          >
            <TextField source="title" />
          </ReferenceField>
          <ReferenceField
            source="bank_account_id"
            reference="bank-accounts"
            link="show"
            label="Bank Account Name"
          >
            <WithRecord
              render={(record) => {
                if (record.payment_method === "crypto")
                  return (
                    <span>{record?.name + " - " + record?.meta?.protocol}</span>
                  );

                return <span>{record?.name}</span>;
              }}
            />
          </ReferenceField>
          <DateField source="created_at" showTime locales="tr-TR" />
          <DateField source="updated_at" showTime locales="tr-TR" />
        </Datagrid>
      )}
    </List>
  );
};

export const BankTransferShow = () => {
  return (
    <Show actions={<ShowActions />}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="amount" />
        <TextField source="currency_code" label="Currency" />
        <TextField source="status" />
        <TextField source="reference_code" />
        <ReferenceField
          source="customer_id"
          reference="customers"
          link="show"
          label="Customer Name"
        >
          <TextField source="title" />
        </ReferenceField>
        <ReferenceField
          source="bank_account_id"
          reference="bank-accounts"
          link="show"
          label="Bank Account Name"
        >
          <WithRecord
            render={(record) => {
              if (record.payment_method === "crypto")
                return (
                  <span>{record?.name + " - " + record?.meta?.protocol}</span>
                );

              return <span>{record?.name}</span>;
            }}
          />
        </ReferenceField>
        <DateField source="created_at" showTime locales="tr-TR" />
        <DateField source="updated_at" showTime locales="tr-TR" />
        <DateField source="approved_at" showTime locales="tr-TR" />
        <DateField source="rejected_at" showTime locales="tr-TR" />
        <DateField source="reject_reason" showTime locales="tr-TR" />
      </SimpleShowLayout>
    </Show>
  );
};

export function BankTransferTab({ reference, target, addLabel }) {
  return (
    <ReferenceManyField
      reference={reference}
      target={target}
      addLabel={addLabel}
    >
      <Datagrid rowClick="show">
        <TextField source="id" />
        <TextField source="amount" />
        <TextField source="status" />
        <TextField source="currency_code" label="Currency" />
        <TextField source="reference_code" />
        <ReferenceField
          source="customer_id"
          reference="customers"
          link="show"
          label="Customer Name"
        >
          <TextField source="title" />
        </ReferenceField>
        <ReferenceField
          source="customer_id"
          reference="customers"
          link="show"
          label="Customer ID"
        >
          <TextField source="id" />
        </ReferenceField>
        <DateField source="created_at" showTime locales="tr-TR" />
        <DateField source="updated_at" showTime locales="tr-TR" />
      </Datagrid>
    </ReferenceManyField>
  );
}
