import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Show,
  TextInput,
  TabbedShowLayout,
  Tab,
  useRecordContext,
  // FileField,
  BulkExportButton,
  ExportButton,
  Pagination,
  WrapperField,
  Button,
  useNotify,
  useDataProvider,
  useRefresh,
  Labeled,
  TopToolbar,
  DateInput,
  WithRecord,
  SimpleShowLayout,
  SimpleForm,
  SaveButton,
  Toolbar,
  Edit,
  // EditButton,
  ShowButton,
  required,
  ReferenceManyField,
  TabbedShowLayoutTabs,
} from "react-admin";

import SendIcon from "@mui/icons-material/Send";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import CurrencyLiraIcon from "@mui/icons-material/CurrencyLira";
import EuroSymbolIcon from "@mui/icons-material/EuroSymbol";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { useMutation } from "react-query";
import { Grid, Stack, Box, Divider } from "@mui/material";
// import { CreateComment } from "./customers/CreateComment";

import { InvoiceTab } from "./invoices/Invoices";
import { ReceiptTab } from "./receipts/Receipts";
import { useParams } from "react-router-dom";
import { BillingAccountTab } from "./billingAccounts";
import { AdAccountTab } from "./adaccounts/adaccounts";
import { WalletTab } from "./wallets/wallets";
import { ActivityLogsTab } from "./activityLogs";
import { BankTransferTab } from "./bankTransfer";
import { UserTab } from "./users";
import { PaymentTab } from "./payments";
import { KycFormsTab } from "./kyc-forms/KycForms";

const filters = [
  <TextInput label="ID" source="id" />,
  <TextInput label="Customer name" source="q" />,
  <DateInput source="created_at_gte" label="Created At Gte (Start Date)" />,
  <DateInput source="created_at_lte" label="Created At Lte (End Date)" />,
];

const BulkActionButtons = () => (
  <>
    <BulkExportButton />
  </>
);

export const CustomerList = () => (
  <List
    filters={filters}
    sort={{ field: "created_at", order: "DESC" }}
    pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} />}
  >
    <Datagrid
      rowClick="show"
      size="medium"
      bulkActionButtons={<BulkActionButtons />}
    >
      <TextField source="id" />
      <TextField source="title" sortable={false} label="Customer Name" />
      <TextField source="kyc_status" />
      <WithRecord
        label="UTM Source"
        render={(record) => {
          /**
           * If utm_source is empty, return null
           * If utm_source is not empty and starts with {, parse it and return utm_source
           * If utm_source is not empty and does not start with {, split it by & and return key value pairs
           * @param {Object} record
           * @returns {string | null}
           * @example
           * utm_source: "utm_source=google&utm_medium=cpc&utm_campaign=brand"
           * utm_source: "{\"utm_source\":\"google\",\"utm_medium\":\"cpc\",\"utm_campaign\":\"brand\"}"
           * utm_source: ""
           * utm_source: null
           */
          if (Object.keys(record.utm_source).length === 0) {
            return null;
          }
          if (record.utm_source && record.utm_source?.split("")[0] === "{") {
            const sources = JSON.parse(record.utm_source || "{}");
            return sources.utm_source ? sources?.utm_source : "";
          }
          const urlParams = new URLSearchParams(record.utm_source);

          if (urlParams.has("utm_source")) {
            return urlParams.get("utm_source");
          }

          return null;
        }}
      />
      <DateField source="created_at" showTime locales="tr-TR" />
      <DateField source="updated_at" showTime locales="tr-TR" />
    </Datagrid>
  </List>
);

const ShowKYCField = () => {
  const record = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const send = useMutation(() => dataProvider.sendKYCVerification(record.id), {
    onSuccess: () => {
      refresh();
      notify("Verification link sent to customer", { type: "success" });
    },
    onError: (err) => {
      notify(err.error, { type: "warning" });
    },
  });
  const handleClick = () => {
    send.mutate();
  };
  return (
    <Stack>
      <Labeled label="KYC Status">
        <TextField source="kyc_status" />
      </Labeled>
      <Labeled label="KYC Form Sender">
        <WrapperField>
          <div>
            <Button
              label="Send KYC Verification"
              variant="outlined"
              startIcon={<SendIcon />}
              onClick={handleClick}
              disabled={send.isLoading}
              size="small"
            />
          </div>
        </WrapperField>
      </Labeled>
    </Stack>
  );
};

const TransactionsHistoryListActions = () => (
  <TopToolbar>
    <ExportButton />
  </TopToolbar>
);

export const CustomerShow = () => {
  const { id } = useParams();
  return (
    <Show>
      <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" />}>
        <Tab label="details">
          <CustomerTab />
        </Tab>
        <Tab label="Billing Accounts" path="billing-accounts">
          <BillingAccountTab
            reference="billing-accounts"
            target="customer_id"
          />
        </Tab>
        <Tab label="Rates" path={"customer-commission-rates/" + id}>
          <ReferenceManyField
            reference={"customer-commission-rates/" + id}
            target="customer_id"
            addLabel={false}
          >
            <Datagrid size="medium">
              <TextField source="id" />
              <TextField source="name" />
              <TextField source="amount" />
              <TextField source="rate" />
              <DateField source="created_at" showTime locales="tr-TR" />
              <DateField source="updated_at" showTime locales="tr-TR" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Ad Accounts" path="ad-accounts">
          <AdAccountTab
            reference="ad-accounts"
            target="customer_id"
            addLabel={false}
          />
        </Tab>
        <Tab label="Wallets" path="wallets">
          <WalletTab
            reference="wallets"
            target="customer_id"
            addLabel={false}
          />
        </Tab>
        <Tab label="Bank Transfers" path="bank-transfers">
          <BankTransferTab
            reference="bank-transfers"
            target="customer_id"
            addLabel={false}
          />
        </Tab>
        <Tab label="Invoices" path="invoices">
          <InvoiceTab reference="invoices" target="customer_id" />
        </Tab>
        <Tab label="Receipts" path="receipts">
          <ReceiptTab reference="receipts" target="customer_id" />
        </Tab>
        <Tab label="Users" path="users">
          <UserTab reference="users" target="customer_id" addLabel={false} />
        </Tab>
        <Tab label="Payments" path="payments">
          <PaymentTab
            reference="payments"
            pagination={
              <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} />
            }
            target="customer_id"
            addLabel={false}
          />
        </Tab>
        <Tab label="Kyc Forms" path="kyc-forms">
          <KycFormsTab
            reference="kyc-forms"
            target="customer_id"
            addLabel={false}
          />
        </Tab>
        <Tab label="Transactions History" path="transactions-history">
          <ReferenceManyField
            reference="payments/wallet-transactions"
            target="customer_id"
            addLabel={false}
          >
            <List
              actions={<TransactionsHistoryListActions />}
              filter={{ customer_id: id }}
            >
              <Datagrid size="medium">
                <TextField source="id" />
                <TextField source="type" />
                <TextField source="kind" />
                <TextField source="customer_id" />
                <TextField source="user_id" />
                <TextField source="user_name" />
                <TextField source="user_email" />
                <TextField source="wallet_id" />
                <TextField source="ad_account_id" />
                <TextField source="ad_account_name" />
                <TextField source="ad_account_alias_name" />
                <TextField source="amount" />
                <TextField source="currency_code" />
                <TextField source="commission_amount" />
                <TextField source="commission_rate" />
                <TextField source="card_fee" />
                <TextField source="card_fee_rate" />
                <DateField source="created_at" showTime locales="tr-TR" />
                <DateField source="updated_at" showTime locales="tr-TR" />
              </Datagrid>
            </List>
          </ReferenceManyField>
        </Tab>
        <Tab label="Activity Logs" path="activity-logs">
          <ActivityLogsTab
            reference="activity-logs"
            target="customer_id"
            addLabel={false}
          />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export const PostEditToolbar = () => {
  return (
    <Toolbar>
      <SaveButton style={{ marginRight: "16px" }} />
      <ShowButton
        variant="contained"
        size="medium"
        label="Back"
        startIcon={<ChevronLeft />}
      />
    </Toolbar>
  );
};

const CustomerTab = () => {
  return (
    <Stack spacing={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="title" label="Customer name" />
            <TextField source="email" />
            <TextField source="workspace" />
            <ShowKYCField />
            <TextField source="is_tax_excluded" label="Tax Excluded" />
            <DateField source="created_at" showTime locales="tr-TR" />
            <DateField source="updated_at" showTime locales="tr-TR" />
            <TextField source="utm_source" label="UTM Sources" />
          </SimpleShowLayout>
        </Grid>
      </Grid>
      <Divider />
      <FinancialSummary />
    </Stack>
  );
};

export const CustomerEdit = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const mutation = useMutation(({ id, title }) =>
    dataProvider.update("customers", { id, data: { title } })
  );

  const mutationOnSuccess = () => {
    notify("Ad account updated", { type: "success" });
    refresh();
  };
  const mutationOnError = (err) => {
    notify(err.error, { type: "warning" });
  };

  const transform = (data) => {
    return { title: data.title };
  };

  return (
    <Edit
      transform={transform}
      mutationMode="optimistic"
      mutationOptions={{
        mutationFn: mutation,
        onSuccess: mutationOnSuccess,
        onError: mutationOnError,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <SimpleShowLayout>
            <TextField source="id" />
            <Labeled label="Customer name">
              <Stack>
                <TextField source="title" />
                <SimpleForm toolbar={<PostEditToolbar />}>
                  <TextInput source="title" validate={required()} autoFocus />
                </SimpleForm>
              </Stack>
            </Labeled>
            <TextField source="email" />
            <TextField source="workspace" />
            <ShowKYCField />
            <TextField source="is_tax_excluded" label="Tax Excluded" />
            <DateField source="created_at" showTime locales="tr-TR" />
            <DateField source="updated_at" showTime locales="tr-TR" />
            <TextField source="utm_source" />
          </SimpleShowLayout>
        </Grid>
      </Grid>
    </Edit>
  );
};

const FinancialSummary = () => {
  const [financialSummary, setFinancialSummary] = React.useState({});
  const { id } = useParams();
  const dataProvider = useDataProvider();

  React.useEffect(() => {
    dataProvider
      .fetchFinancialSummaryByCustomerId(id)
      .then((response) => setFinancialSummary(response.data));
  }, []);

  if (!financialSummary) {
    return null;
  }

  function groupByCurrency(data) {
    const groupedData = {};

    for (const key in data) {
      if (data[key] !== null) {
        data[key].forEach((item) => {
          if (!groupedData[key]) {
            groupedData[key] = {};
          }
          groupedData[key][item.currency_code] = parseFloat(item.total_amount);
        });
      } else {
        groupedData[key] = {};
      }
    }

    const allCurrencyCodes = new Set();
    for (const key in groupedData) {
      for (const currency in groupedData[key]) {
        allCurrencyCodes.add(currency);
      }
    }

    for (const key in groupedData) {
      allCurrencyCodes.forEach((currency) => {
        if (!(currency in groupedData[key])) {
          groupedData[key][currency] = 0;
        }
      });
    }

    const total_total = {};
    allCurrencyCodes.forEach((currency) => {
      total_total[currency] = (
        (groupedData.total_payments?.[currency] || 0) -
        (groupedData.total_payment_refund?.[currency] || 0) -
        (groupedData.total_spend?.[currency] || 0) -
        (groupedData.total_wallet_balance?.[currency] || 0) -
        (groupedData.total_ad_account_balance?.[currency] || 0) -
        (groupedData.total_commission?.[currency] || 0) +
        (groupedData.total_commission_refund?.[currency] || 0)
      ).toFixed(2);
    });

    groupedData.total_total = total_total;

    return groupedData;
  }

  const groupedData = groupByCurrency(financialSummary);
  return (
    <>
      <Stack>Financial Summary</Stack>
      <Stack spacing={Object.keys(financialSummary).length + 1} direction="row">
        {Object.entries(groupedData).map(([key, value]) => {
          return (
            <Labeled
              key={key}
              label={key.split("_").slice(1).join(" ")}
              whiteSpace={"nowrap"}
              sx={{
                textTransform: "capitalize",
              }}
            >
              <Box sx={{ fontSize: "14px" }}>
                {Object.entries(value).length === 0 ? "No data" : null}
                {Object.entries(value).map(([key, value]) => {
                  function switchCurrencyIcon(currency) {
                    switch (currency) {
                      case "USD":
                        return <AttachMoneyIcon fontSize="14px" />;
                      case "EUR":
                        return <EuroSymbolIcon fontSize="14px" />;
                      case "TRY":
                        return <CurrencyLiraIcon fontSize="14px" />;
                      default:
                        return null;
                    }
                  }
                  {
                    Object.entries(value).length === 0 ? "No data" : null;
                  }
                  return (
                    <Box
                      key={key}
                      height={30}
                      alignContent={"center"}
                      display={"flex"}
                      alignItems={"center"}
                    >
                      {switchCurrencyIcon(key)}
                      {value}
                    </Box>
                  );
                })}
              </Box>
            </Labeled>
          );
        })}
      </Stack>
    </>
  );
};
