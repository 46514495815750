import {
  Datagrid,
  DateField,
  List,
  Pagination,
  ReferenceManyField,
  ReferenceOneField,
  TextField,
  WithRecord,
} from "react-admin";

export function ActivityLogs() {
  return (
    <List
      pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 200]} />}
      sort={{ field: "created_at", order: "DESC" }}
    >
      <Datagrid size="medium">
        <TextField source="id" />
        <ReferenceOneField
          target="email"
          source="actor"
          reference="users"
          link="show"
        >
          <TextField source="email" />
        </ReferenceOneField>
        <WithRecord
          label="Reference"
          render={(record) => {
            if (!record.action) return null;

            // eslint-disable-next-line no-unused-vars
            const [_, reference] = record.action.split(".");

            const fieldSource = {
              users: "email",
              "ad-accounts": "account_name",
              customers: "title",
              "bank-transfers": "id",
              "kyc-forms": "id",
              demands: "object_name",
              wallets: "id",
            }[reference];

            if (!fieldSource) return null;

            return (
              <ReferenceOneField
                source="ref_id"
                reference={reference}
                target="id"
                link="show"
              >
                <TextField source={fieldSource} />
              </ReferenceOneField>
            );
          }}
        />
        <TextField source="action" />
        <TextField source="comment" />
        <TextField source="status" />
        <TextField source="ip" />
        <DateField source="created_at" showTime locales="tr-TR" />
      </Datagrid>
    </List>
  );
}

export function ActivityLogsTab({ reference, target, addLabel }) {
  return (
    <ReferenceManyField
      reference={reference}
      target={target}
      addLabel={addLabel}
      pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} />}
      sort={{ field: "created_at", order: "DESC" }}
    >
      <Datagrid size="medium">
        <TextField source="id" />
        <ReferenceOneField
          target="email"
          source="actor"
          reference="users"
          link="show"
        >
          <TextField source="email" />
        </ReferenceOneField>
        <WithRecord
          label="Reference"
          render={(record) => {
            if (!record.action) return null;

            // eslint-disable-next-line no-unused-vars
            const [_, reference] = record.action.split(".");

            const fieldSource = {
              users: "email",
              "ad-accounts": "account_name",
              customers: "title",
              "bank-transfers": "id",
              "kyc-forms": "id",
              demands: "object_name",
              wallets: "id",
            }[reference];

            if (!fieldSource) return null;

            return (
              <ReferenceOneField
                source="ref_id"
                reference={reference}
                target="id"
                link="show"
              >
                <TextField source={fieldSource} />
              </ReferenceOneField>
            );
          }}
        />
        <TextField source="action" />
        <TextField source="comment" />
        <TextField source="status" />
        <TextField source="ip" />
        <DateField source="created_at" showTime locales="tr-TR" />
      </Datagrid>
    </ReferenceManyField>
  );
}
